import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useEffect, useState } from "react";
import uzFlag from "./images/flags/uz.png";
import RuFlag from "./images/flags/russia.jpg";
import ContentComp from "./components/content";

function App() {
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [selectedLang, setselectedLang] = useState(
    localStorage.getItem("lang") || 2
  );

  useEffect(() => {
    setselectedLang(localStorage.getItem("lang") || 2);
  }, []);

  const langs = [
    {
      id: 1,
      title: "Uzb",
      img: uzFlag,
    },
    {
      id: 2,
      title: "Ru",
      img: RuFlag,
    },
  ];

  const toggle = () => {
    setdropdownOpen(!dropdownOpen);
  };

  const onMouseEnter = () => {
    setdropdownOpen(true);
  };

  const onMouseLeave = () => {
    setdropdownOpen(false);
  };

  const changeLang = (langId) => {
    setselectedLang(langId);
    localStorage.setItem("lang", langId);
  };

  return (
    <div className="App">
      <div className="background-pseudo">
      <div className="container">
        <div className="lang pt-3">
          <Dropdown
            className="d-inline-block"
            onMouseOver={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle
              style={{ background: "transparent", border: "none" }}
              caret
            >
              {langs.filter((i) => i.id == selectedLang)[0].title}
              <img
                src={langs.filter((i) => i.id == selectedLang)[0].img}
                className="ms-2"
                style={{
                  width: "25px",
                  height: "15px",
                  objectFit: "cover",
                }}
                alt=""
              />
            </DropdownToggle>
            <DropdownMenu>
              {langs.map((item, index) => (
                <DropdownItem
                  onClick={() => {
                    changeLang(item.id);
                  }}
                  key={index}
                >
                  {item.title}{" "}
                  <img
                    src={item.img}
                    className="ms-2"
                    style={{
                      width: "25px",
                      height: "15px",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    alt=""
                  />
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <ContentComp selectedLang={selectedLang} />
      </div>
      </div>
    </div>
  );
}

export default App;
