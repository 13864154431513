import React from "react";
import HeaderImg from "../images/header-img.png";
import nbpLogo from "../images/nbp-logo.png";
import tianLogo from "../images/tian-logo.png";

const ContentComp = ({selectedLang}) => {

  return (
    <div className="content-wrapper">
      <header>
        <div className="d-flex align-items-center justify-content-center">
          <p className="mb-0">TIAN </p>
          <img src={HeaderImg} alt="" />
        </div>
        <p className="header-title mb-0">
          {selectedLang == 1 ? "Toshkentdagi Informatsion Ko’chmas Mulk Agentligi":"Ташкентское Информационное Агентство Недвижимости"}
        </p>
      </header>
      <div className="row">
        <div className="col-sm-6">
          <div className="logo-wrapper text-center py-4">
            <a href="https://info.tian.uz/" target="_blank">
              <img src={nbpLogo} className="w-50" alt="" />
            </a>
            <h3 className="text-white mt-4">{selectedLang == 1 ? "Ko'chmas mulk qidiruvi!":"База, поиск недвижимости!"}</h3>
            <h5 className="mb-4 text-white">{selectedLang == 1 ? "(kvartiralar, uchastkalar, noturar joylar)":"(квартиры,участки,нежилой)"}</h5>
            <a href="https://info.tian.uz/" target="_blank">
              <button className="to-site-btn">{selectedLang == 1 ? "Saytga O'tish":"Перейти на сайт"}</button>
            </a>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="logo-wrapper text-center py-4">
            <a href="https://agent.tian.uz" target="_blank">
              <img src={tianLogo} className="w-50" alt="" />
            </a>
            <h3 className="mt-4 mb-0 text-white">{selectedLang == 1 ? "Ko’chmas Mulk Agentligi":"Агентство недвижимости"}</h3>
            <h5 className="mb-4 text-white">{selectedLang == 1 ? "Rieltorlik  xizmatlari" : "Услуги в сфере недвижимости"}</h5>
            <a href="https://agent.tian.uz" target="_blank">
              <button className="to-site-btn">{selectedLang == 1 ? "Saytga O'tish":"Перейти на сайт"}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContentComp;
